<template>
  <section class="JCI container970">
    <div class="top-banner">
      <img src="../../assets/images/public/small_banner.png" alt="" class="small_banner">
      <h3>JCI</h3>
      <el-button style="background: #50b5ff; border: none; border-radius: 8px" type="primary" size="large"
        @click="$router.push('/jci-form')">Make a Donation</el-button>
    </div>

    <el-card class="table-card">
      <div class="mb-3 p-1 d-flex justify-content-between align-items-center" style="border-bottom: 1px solid #eee">
        <div>
          <h3>Donors</h3>
        </div>
        <div class="d-flex align-items-center">
          <el-input v-model="keyword" @change="(page = 1), getDonateRecord()" placeholder="Keyword"
            suffix-icon="el-icon-search" clearable style="width: 180px" class="mr-3 ml-2">
          </el-input>
          <OrganizationSel @getOrganizationParams="getOrganizationParams" />
        </div>
        <!-- <div class="col-4">
          <el-select class="w-100" v-model="organization_id" filterable remote reserve-keyword
            :remote-method="organizationSearch" @change="page=1,getDonateRecord()"
            placeholder="Enter keyword search organization" clearable>
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div> -->
      </div>
      <el-table :header-row-style="{ background: '#f00 !important' }" :data="tableData" style="width: 100%">
        <el-table-column prop="avatar" label="Donor" show-overflow-tooltip width="65">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div class="user-img img-fluid">
              <el-avatar :size="40" :src="scope.row.avatar" @error="true">
                <img src="@/assets/images/public/user.png" />
              </el-avatar>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="real_name" label="" show-overflow-tooltip></el-table-column>
        <el-table-column prop="organization" label="Organization" show-overflow-tooltip></el-table-column>
        <el-table-column prop="donor_country" label="Country" show-overflow-tooltip></el-table-column>
        <el-table-column prop="donate_to" label="Donate To" show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="donation_amount" 
          show-overflow-tooltip
          width="150"
          label="Donation Amount"
        >
          <template  slot-scope="scope">
            $ {{scope.row.donation_amount}}
          </template>
        </el-table-column>
        <el-table-column prop="donation_at" label="Donation At" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="py-3" style="text-align: right">
        <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
          @current-change="changePage" hide-on-single-page>
        </el-pagination>
      </div>
    </el-card>
  </section>
</template>

<script>
  import OrganizationSel from "@/components/ComComponents/OrganazationSel";
  export default {
    data() {
      return {
        tableData: [],
        keyword: "",
        organization_id: "",
        orgList: [],
        page: 1,
        size: 10,
        total: 0
      };
    },

    components: { OrganizationSel },

    created() {
      this.getDonateRecord();
    },

    methods: {
      // organizationSearch(query) {
      //   this.$http
      //     .organizationSearch({ organization_name: query })
      //     .then((res) => {
      //       if (res.status == 200) {
      //         this.orgList = res.data;
      //       }
      //     });
      // },

      // 选择组织
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data));
        let level = OrganData.level.id;
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id;
            this.getDonateRecord();
            break;
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id;
            this.getDonateRecord();
            break;
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id;
            this.getDonateRecord();
            break;
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id;
            this.getDonateRecord();
            break;
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id;
            this.getDonateRecord();
            break;
          }
        }
      },

      getDonateRecord() {
        this.$http
          .getDonateRecord({
            donated_type: 1,
            page: this.page,
            per_page: this.size,
            keyword: this.keyword,
            organization_id: this.organization_id,
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.total = res.data.total;
          });
      },

      changePage(value) {
        this.page = value;
        this.getDonateRecord();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .JCI {
    .top-banner {
      // height: 230px;
      border-radius: 5px;
      /* background: url("../../assets/images/page-img/profile-bg1.jpg") no-repeat 100% 100%; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      background-color: rgb(66, 102, 176);

      .small_banner {
        height: 100%;
        position: absolute;
        // left: 0;
      }


      >h3 {
        color: #fff;
        font-size: 30px;
        margin-bottom: 37px;
      }

      >.el-button {
        /* width: 100px; */
      }
    }

    ::v-deep .el-select {
      width: 150px;
    }

    ::v-deep .el-input__inner {
      height: 45px;
      border-radius: 11px;
    }
  }
  @media (min-width: 600px) {
    .top-banner {
      height: 230px;
      .small_banner {
        left: 70px;
      }
    }
  }
  @media (max-width: 600px) {
    .top-banner{
      height: 180px;
      .small_banner {
        width: 70px;
        left: 25px;
      }
    }
  }
</style>